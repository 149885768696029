import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserPosition, setUserRole, unSetUserPosotion, unSetUserRole } from 'redux/companiesUsers-service/actions';
// types
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import { USER_ROLES } from '@joc/api-gateway';
// components
import PopupContainer from 'shared/components/PopupContainer';
import WhiteContainer from 'shared/components/WhiteContainer';
import TableMain from 'shared/components/Table/TableMain';
import TableMenu from 'components/Organization/TableMenu';
import TableHead from 'shared/components/Table/TableHead';
import TableBody from 'shared/components/Table/TableBody';
import TableRow from 'shared/components/Table/TableRow';
import Loader from 'shared/components/Loader';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import Checkbox from 'shared/components/Checkbox';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import CreatePositionPopup from '../CreatePositionPopup';
// helpers
import { getAllUserRolesInEachOrg, getSortedUserOrgsList } from './helpers';
// context
import { ISortedRolesItem, UserTableDataContext } from '../../UsersTable';
// styles
import styles from './EditRolePopup.module.scss';
import PopupConfirmation from '../../../../../shared/components/PopupConfirmation';

type EditRolePopupProps = {
	setIsShowPopup: () => void;
};

const EditRolePopup: FC<EditRolePopupProps> = ({ setIsShowPopup }) => {
	const { isNeedCloseModal, setIsNeedCloseModal, userData } = useContext(UserTableDataContext);
	const [userToDelete, setUserToDelete] = useState<{
		userId: number;
		orgId: string;
		value: number;
		message: string;
		callback: () => void;
	} | null>(null);

	const dispatch = useDispatch();

	const [isShowCreatePositionPopup, setIsShowCreatePositionPopup] = useState(false);
	const [isSortASC, setIsSortASC] = useState<boolean>(true);

	const userRolesInEachOrg = useMemo(() => getAllUserRolesInEachOrg(userData.roles), [userData.roles]);
	const sortedUserOrgsList = useMemo(
		() => getSortedUserOrgsList(userRolesInEachOrg, isSortASC),
		[userRolesInEachOrg, isSortASC]
	);

	useEffect(() => {
		if (isNeedCloseModal) {
			setIsShowPopup();
			if (setIsNeedCloseModal) {
				setIsNeedCloseModal(false);
			}
		}
	}, [isNeedCloseModal]);

	const changeUserRole = useCallback(
		(checked: boolean, value?: number, orgId?: string) => {
			if (value && orgId) {
				const userRoleDetails = sortedUserOrgsList.find(user => user.organizationId === orgId);

				if (checked) {
					dispatch(setUserRole(+userData.id, orgId, value));
				} else if (
					(userRoleDetails && userRoleDetails.roleList.length && userRoleDetails.roleList.length > 1) ||
					sortedUserOrgsList.length > 1
				) {
					dispatch(unSetUserRole(+userData.id, orgId, value));
				} else {
					setUserToDelete({
						userId: +userData.id,
						orgId,
						value,
						message: 'The user without roles will be deleted.',
						callback: () => {
							dispatch(unSetUserRole(+userData.id, orgId, value));
							if (setIsNeedCloseModal) setIsNeedCloseModal(true);
							refresh();
						},
					});
				}
			}
		},
		[sortedUserOrgsList]
	);

	const changeUserPosition = useCallback(
		(checked: boolean, value?: number, orgId?: string) => {
			if (value && orgId) {
				const userRoleDetails = sortedUserOrgsList.find(user => user.organizationId === orgId);

				if (checked) {
					dispatch(setUserPosition(+userData.id, orgId, value));
				} else if (userRoleDetails && userRoleDetails.roleList.length && userRoleDetails.roleList.length >= 1) {
					if (
						userRoleDetails.roleList.some(role => role.roleName === 'VOLUNTEER') ||
						sortedUserOrgsList.length > 1
					) {
						dispatch(unSetUserPosotion(+userData.id, orgId, value));
					} else {
						setUserToDelete({
							userId: +userData.id,
							orgId,
							value,
							message: 'The user without a Volunteer role or any positions will be deleted.',
							callback: () => {
								dispatch(unSetUserPosotion(+userData.id, orgId, value));
								if (setIsNeedCloseModal) setIsNeedCloseModal(true);
								refresh();
							},
						});
					}
				} else {
					setUserToDelete({
						userId: +userData.id,
						orgId,
						value,
						message: 'The user without positions will be deleted.',
						callback: () => {
							dispatch(unSetUserPosotion(+userData.id, orgId, value));
							if (setIsNeedCloseModal) setIsNeedCloseModal(true);
							refresh();
						},
					});
				}
			}
		},
		[sortedUserOrgsList]
	);

	const refresh = useCallback(() => {
		setUserToDelete(null);
	}, [sortedUserOrgsList]);

	const getIsDefaultRoleChecked = (organization: ISortedRolesItem, role: USER_ROLES) =>
		organization.roleList.some(({ roleName }) => roleName === role);
	const getIsOwner = (organization: ISortedRolesItem) =>
		userData.userOrganizations?.some(
			({ organizationId, position }) =>
				organizationId === organization.organizationId && position?.organizationRoleName === 'Owner'
		);
	const getIsMember = (organization: ISortedRolesItem) =>
		userData.userOrganizations?.some(
			({ organizationId, position }) =>
				organizationId === organization.organizationId && position?.organizationRoleName === 'Member'
		);

	const showCreatePositionPopupHandler = () => {
		setIsShowCreatePositionPopup(prev => !prev);
	};

	return (
		<PopupContainer setIsShowPopup={setIsShowPopup}>
			<WhiteContainer title="Role in organization" parentClassName={styles.whiteContainer}>
				<TableMenu searchChangeHandler={() => {}} />
				<TableMain>
					<TableHead className={styles.table__header} callChild="roles">
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
							text={TABLE_HEADERS.organizations}
							isSortDisabled={!userData.roles?.length || userData.roles?.length === 1}
							clickHandler={() => {
								setIsSortASC(!isSortASC);
							}}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.volunteerRole}
							isSortDisabled={true}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.admin}
							isSortDisabled={true}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.marketer}
							isSortDisabled={true}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.coordinator}
							isSortDisabled={true}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.owner}
							isSortDisabled={true}
						/>
						<TableHeadCell
							itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
							text={TABLE_HEADERS.member}
							isSortDisabled={true}
						/>
					</TableHead>
					{!userData.roles ? (
						<div className={styles.loader}>
							<Loader />
						</div>
					) : (
						<TableBody>
							{sortedUserOrgsList.map((organization, index) => (
								<TableRow key={index} callChild="roles">
									<FullNameCell
										firstName={organization.organization?.organizationName || 'Private'}
										lastName=""
										isUnsetMaxWidth={true}
									/>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsDefaultRoleChecked(organization, USER_ROLES.VOLUNTEER)}
											value={3}
											changeHandle={(checked, value) => {
												changeUserRole(checked, value, organization.organizationId);
											}}
										/>
									</div>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsDefaultRoleChecked(organization, USER_ROLES.ADMIN)}
											value={4}
											changeHandle={(checked, value) =>
												changeUserRole(checked, value, organization.organizationId)
											}
											disabled={!organization.organization?.organizationName}
										/>
									</div>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsDefaultRoleChecked(organization, USER_ROLES.MARKETER)}
											value={5}
											changeHandle={(checked, value) =>
												changeUserRole(checked, value, organization.organizationId)
											}
											disabled={!organization.organization?.organizationName}
										/>
									</div>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsDefaultRoleChecked(
												organization,
												USER_ROLES.COORDINATOR
											)}
											value={6}
											changeHandle={(checked, value) =>
												changeUserRole(checked, value, organization.organizationId)
											}
											disabled={!organization.organization?.organizationName}
										/>
									</div>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsOwner(organization)}
											value={1}
											changeHandle={(checked, value) =>
												changeUserPosition(checked, value, organization.organizationId)
											}
											disabled={!organization.organization?.organizationName}
										/>
									</div>
									<div className={styles.checkboxWrapper}>
										<Checkbox
											defaultChecked={getIsMember(organization)}
											value={2}
											changeHandle={(checked, value) =>
												changeUserPosition(checked, value, organization.organizationId)
											}
											disabled={!organization.organization?.organizationName}
										/>
									</div>
								</TableRow>
							))}
						</TableBody>
					)}
				</TableMain>
			</WhiteContainer>
			<div className={styles.buttons_block}>
				<ButtonDefault
					title="+Add position"
					classList="secondary_active, lg"
					clickHandler={showCreatePositionPopupHandler}
				/>
			</div>
			{isShowCreatePositionPopup && (
				<CreatePositionPopup setIsShowPopup={showCreatePositionPopupHandler} userId={+userData.id} />
			)}
			{userToDelete && (
				<PopupConfirmation
					confirmClickHandler={() => userToDelete?.callback()}
					subtitle={userToDelete.message}
					cancelClickHandler={() => setUserToDelete(null)}
					setIsShowPopup={() => setUserToDelete(null)}
				/>
			)}
		</PopupContainer>
	);
};

export default EditRolePopup;
