import MainPageLayout from 'components/Organization/MainPageLayout';
import React, { FC } from 'react';

import OppListContent from 'shared/components/OppListContent';
// styles
import styles from './SuperAdminOppoList.module.scss';

const SuperAdminOppoList: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<h1 className="title">Opportunities List</h1>
					<OppListContent />
				</div>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminOppoList;
