import styles from './UploadQuotesUnput.module.scss';
import { ReactComponent as UploadSVG } from 'assets/image/upload-icon.svg';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { IQuoteRequest } from '@joc/api-gateway';
import { parseQuotesXlsx, quoteValidation } from './helpers';
import { ValidationError } from 'yup';
import moment from 'moment-mini';
import Dropzone from 'react-dropzone';

type ValidationErrors<Type> = {
	[Property in keyof Type]?: string;
};

export type UploadedQuote = IQuoteRequest & {
	errors?: ValidationErrors<IQuoteRequest> & { nonUniqueDate?: string };
};

interface IUploadQuotesInputProps {
	handleInput: (quotes: Array<UploadedQuote>) => void;
	setErrorsCount: React.Dispatch<React.SetStateAction<number>>;
}

export const UploadQuotesInput: FC<IUploadQuotesInputProps> = ({ handleInput, setErrorsCount }) => {
	const [file, setFile] = useState<File>();

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files?.length) {
			setFile(Array.from(e.target.files)[0]);
		}
	};

	const handleDrop = (e: Array<File>) => {
		if (e.length) {
			setFile(e[0]);
		}
	};

	useEffect(() => {
		(async () => {
			if (file) {
				const parsedQuotes = await parseQuotesXlsx(file);
				let errors = 0;

				const uniqueElements = new Set();

				const duplicateDates: Array<string> = [];

				parsedQuotes.forEach(quote => {
					if (quote) {
						const date = moment(quote.startDate).format('YYYY-MM-DD');
						if (uniqueElements.has(date)) {
							duplicateDates.push(date);
						} else {
							uniqueElements.add(date);
						}
					}
				});

				const validatedQuotes = await Promise.all(
					parsedQuotes.map(async quote => {
						try {
							if (
								quote?.startDate &&
								duplicateDates.includes(moment(quote?.startDate).format('YYYY-MM-DD'))
							) {
								const validatedQuote = await quoteValidation.validate(quote, { abortEarly: false });
								errors += 1;
								return {
									...validatedQuote,
									errors: { nonUniqueDate: 'Multiple quotes for the same date are not allowed.' },
								};
							}
							return (await quoteValidation.validate(quote, { abortEarly: false })) as UploadedQuote;
						} catch (error) {
							if (error instanceof ValidationError) {
								errors += 1;
								const validationErrors: Record<string, string> = {};
								error.inner.forEach((error: any) => {
									if (error.path !== undefined) {
										validationErrors[error.path] = error.errors[0];
									}
								});
								if (
									quote?.startDate &&
									duplicateDates.includes(moment(quote?.startDate).format('YYYY-MM-DD'))
								) {
									validationErrors.nonUniqueDate =
										'Multiple quotes for the same date are not allowed.';
								}
								return { ...quote, errors: validationErrors } as UploadedQuote;
							}
							return quote as UploadedQuote;
						}
					})
				);

				setErrorsCount(errors);

				handleInput(validatedQuotes);
			}
		})();
	}, [file]);

	return (
		<div className={styles.wrap}>
			<Dropzone
				accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				multiple={false}
				onDrop={handleDrop}
			>
				{({ getRootProps }) => {
					return (
						<div className={styles.input} {...getRootProps()}>
							<UploadSVG className={styles.uploadIcon} />
							<div className={styles.uploadText}>
								<input
									id="file-upload"
									type="file"
									accept=".csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									onInput={handleUpload}
									// @ts-ignore
									// eslint-disable-next-line no-return-assign
									onClick={e => (e.target.value = null)}
								/>
								<label htmlFor="file-upload" className={styles.uploadLabel}>
									Click to upload
								</label>
								<span>or drag and drop</span>
							</div>
						</div>
					);
				}}
			</Dropzone>
		</div>
	);
};
