import * as XLSX from 'xlsx';
import * as Yup from 'yup';

import { UploadedQuote } from './index';

interface IExcelQuoteRequest {
	message: string;
	startDate?: number;
}

const excelDateToJSDate = (excelDate: number): Date => {
	const excelEpoch = new Date(1899, 11, 30); // December 30, 1899
	const millisecondsPerDay = 24 * 60 * 60 * 1000;

	const convertedDate = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

	// Set time to noon (12:00:00)
	return new Date(convertedDate.getFullYear(), convertedDate.getMonth(), convertedDate.getDate(), 12, 0, 0);
};

const getParsedData = (sheet: XLSX.WorkSheet) => {
	const rowsArray = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as Array<Array<string>>;
	const rowIndex = rowsArray.findIndex(row => row.includes('Start date'));
	const ref = sheet['!ref']!;

	let range = ref;

	if (rowIndex > 0) {
		const [refStart, refEnd] = ref.split(':');
		const [refStartX] = refStart.split('');
		const newRefStart = `${refStartX}${rowIndex + 1}`;
		range = `${newRefStart}:${refEnd}`;
	}

	return XLSX.utils.sheet_to_json(sheet, {
		range,
		defval: '',
		blankrows: false,
	}) as Array<IExcelQuoteRequest>;
};

export const parseQuotesXlsx = (file: File) => {
	return new Promise<Array<Partial<UploadedQuote>>>(resolve => {
		const reader = new FileReader();
		reader.onload = e => {
			const data = e.target?.result;
			const excelData = XLSX.read(data, { type: 'binary' });
			const sheetName = excelData.SheetNames[0];
			const sheet = excelData.Sheets[sheetName];

			const parsedData = getParsedData(sheet);

			const normalizeParsedData = parsedData.map(parsedUserValuesObj => {
				let normalizedUserValuesObj = {} as IExcelQuoteRequest;

				Object.keys(parsedUserValuesObj).forEach(key => {
					const normalizeKeyName = key
						.split(' ')
						.filter(k => k !== '-')
						.map((word, idx) => {
							word = word.toLowerCase();
							if (idx !== 0) word = word[0].toUpperCase() + word.slice(1);
							return word;
						})
						.join('');

					normalizedUserValuesObj = {
						...normalizedUserValuesObj,
						[normalizeKeyName]: parsedUserValuesObj[key as keyof IExcelQuoteRequest],
					};
				});

				return normalizedUserValuesObj;
			});

			const importedDailyQuotes: Array<Partial<UploadedQuote>> = normalizeParsedData.map(quote => ({
				...quote,
				message: quote.message.trim(),
				startDate: quote.startDate ? excelDateToJSDate(quote.startDate) : undefined,
			}));

			resolve(importedDailyQuotes);
		};

		reader.readAsBinaryString(file);
	});
};

export const quoteValidation = Yup.object({
	message: Yup.string().max(80, 'Daily quote message is too long').required('Daily quote message is required'),
	startDate: Yup.date().typeError('Invalid date').required('Date is required'),
});
