// TODO: Helpers

import * as Yup from 'yup';
import { MAX_NAME_LENGTH, MAX_TITLE_LENGTH } from './constants';
import { imagePathSchema } from '../../../../core/validation';
import moment from 'moment-mini';

const nameSchema = Yup.string()
	.min(2, 'Challenge title should be more than 2 characters')
	.trim()
	.max(MAX_NAME_LENGTH, `Challenge title must be at most ${MAX_NAME_LENGTH} characters`)
	.required('Challenge title is required');

const titleSchema = Yup.string()
	.min(2, 'Challenge subtitle should be more than 2 characters')
	.trim()
	.max(MAX_TITLE_LENGTH, `Challenge subtitle must be at most ${MAX_TITLE_LENGTH} characters`)
	.required('Challenge subtitle is required');

const descriptionSchema = Yup.string()
	.min(2, 'Description should be more than 2 characters')
	.test('Description is required', 'Description is required', description => {
		const element = document.createElement('div');
		element.innerHTML = description || '';

		return !!element.innerText?.length;
	})
	.required('Description is required');

const startDateSchema = Yup.date()
	.nullable(true)
	.required('Start date is required')
	.test({
		name: 'moreThenDeadlineDate',
		message: 'Start date must be before deadline date',
		test(value) {
			if (value) {
				return moment(this.parent.endDate, 'DD.MM.YYYY').isSameOrAfter(moment(value, 'DD.MM.YYYY'), 'day');
			}

			return true;
		},
	})
	.typeError('Please, fill full date');

const endDateSchema = Yup.date()
	.nullable(true)
	.required('End date is required')
	.test({
		name: 'moreThenStartDate',
		message: 'Deadline date must be after start date',
		test(value) {
			if (value) {
				return moment(value, 'DD.MM.YYYY').isSameOrAfter(moment(this.parent.startDate, 'DD.MM.YYYY'), 'day');
			}

			return true;
		},
	})
	.typeError('Please, fill full date');

export const startTimeSchema = Yup.string()
	.test({
		name: 'moreThenDeadlineTime',
		message: 'Start time must be before deadline time',
		test(value) {
			const { startDate, endDate, endTime } = this.parent;

			if (value && endTime && moment(startDate).isSame(moment(endDate), 'day')) {
				const [startHour, startMinute] = value.split(':');
				const [endHour, endMinute] = endTime.split(':');

				const startTimeTimestamp = moment().set({ hour: +startHour, minute: +startMinute }).format('X');
				const endTimeTimestamp = moment().set({ hour: +endHour, minute: +endMinute }).format('X');
				return startTimeTimestamp < endTimeTimestamp;
			}

			return true;
		},
	})
	.required('Start time is required');

export const endTimeSchema = Yup.string()
	.required('Deadline time is required')
	.test({
		name: 'lessThenStartTime',
		message: 'Deadline time must be after start time',
		test(value) {
			const { startDate, endDate, startTime } = this.parent;

			if (value && startTime && moment(startDate).isSame(moment(endDate), 'day')) {
				const [startHour, startMinute] = startTime.split(':');
				const [endHour, endMinute] = value.split(':');

				const endTimeTimestamp = moment().set({ hour: +endHour, minute: +endMinute }).format('X');
				const startTimeTimestamp = moment().set({ hour: +startHour, minute: +startMinute }).format('X');
				return startTimeTimestamp < endTimeTimestamp;
			}
			return true;
		},
	});

export const challengeValidationSchema = Yup.object().shape({
	name: nameSchema,
	title: titleSchema,
	description: descriptionSchema,
	startDate: startDateSchema,
	endDate: endDateSchema,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	imagePath: imagePathSchema,
	chesedIdeas: Yup.array()
		.test('Chesed ideas is required', 'Chesed ideas is required', chesedIdeas => !!chesedIdeas?.[0]?.length)
		.required('Chesed ideas is required'),
});
