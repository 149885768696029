import styles from './ImportQuotesTab.module.scss';
import { UploadedQuote, UploadQuotesInput } from './UploadQuotesInput';
import { IQuoteRequest, QuoteRequest } from '@joc/api-gateway';
import ButtonDefault from '../../../../Buttons/ButtonsDefault';
import cx from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { ImportedQuotesTable } from './ImportedQuotesTable';
import { createDailyQuote, deleteDailyQuote } from 'redux/dailyQuotes-service/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isSameDay } from 'date-fns';
import { selectorGetQuotesAll } from 'redux/dailyQuotes-service/selector';

interface IImportQuoteTabProps {
	onClose: () => void;
	isEditing?: boolean;
}

export const ImportQuotesTab: FC<IImportQuoteTabProps> = ({ onClose, isEditing = false }) => {
	const dispatch = useDispatch();

	const dailyQuotes = useSelector(selectorGetQuotesAll);

	const [uploadedQuotes, setUploadedQuotes] = useState<Array<UploadedQuote>>([]);
	const [errorsCount, setErrorsCount] = useState(0);

	const sortedQuotes = useMemo(
		() => uploadedQuotes.sort((a, b) => a.startDate?.getTime() - b.startDate?.getTime()),
		[uploadedQuotes]
	);

	const handleInput = (quotes: Array<IQuoteRequest>) => setUploadedQuotes(quotes);

	const handleImport = async () => {
		await Promise.all(
			uploadedQuotes.map(async uploadedQuote => {
				const quotesWithSameDate = dailyQuotes.filter(quote =>
					isSameDay(quote.startDate, uploadedQuote.startDate)
				);

				return Promise.all(quotesWithSameDate.map(async quote => dispatch(deleteDailyQuote(quote))));
			})
		);

		dispatch(createDailyQuote(uploadedQuotes as Array<QuoteRequest>));

		onClose();
	};

	const downloadTemplate = () => {
		const templateFileLink = `${process.env.PUBLIC_URL}/assets/documents/importDailyQuotesTemplate.xlsx`;

		fetch(templateFileLink)
			.then(resp => (resp.status === 200 ? resp.blob() : Promise.reject(new Error('error'))))
			.then(blob => {
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = 'daily_quotes_template.xls';

				document.body.appendChild(a);
				a.click();

				window.URL.revokeObjectURL(url);
			});
	};

	const handleQuoteDelete = (quoteIndex: number) => {
		setUploadedQuotes(sortedQuotes.filter((quote, index) => index !== quoteIndex));
	};

	const handleClearAll = () => {
		setUploadedQuotes([]);
	};

	return (
		<div className={styles.wrap}>
			<UploadQuotesInput handleInput={handleInput} setErrorsCount={setErrorsCount} />

			<button className={styles.templateDownload} onClick={downloadTemplate}>
				Download list template
			</button>

			{uploadedQuotes.length ? (
				<>
					<div className={styles.tableHeader}>
						<span>
							Detected {uploadedQuotes.length} Daily Quotes
							{errorsCount ? (
								<>
									{', '}
									<span style={{ color: '#D00074' }}>{`but ${errorsCount} have errors.`}</span>
								</>
							) : (
								<></>
							)}
						</span>

						<button title="Clear all" className={styles.clearAllButton} onClick={handleClearAll}>
							Clear all
						</button>
					</div>

					<ImportedQuotesTable quotes={sortedQuotes} handleQuoteDelete={handleQuoteDelete} />
				</>
			) : null}

			<div className={styles.footer}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.footer_button}
					title="Cancel"
					clickHandler={onClose}
				/>
				<ButtonDefault
					classList={['primary', 'lg']}
					parentClassName={cx(styles.footer_button, {
						[styles.disable_submit]: uploadedQuotes.length,
					})}
					title={isEditing ? 'Save' : 'Create'}
					disabled={!uploadedQuotes.length || !!errorsCount}
					clickHandler={handleImport}
				/>
			</div>
		</div>
	);
};
