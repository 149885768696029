import React, { createContext, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'qs';
// core
import { INITIAL_PAGINATION } from 'core/constants';
// redux
import { resetError } from 'redux/error-service/action';
import { resetOpportunities } from 'redux/opportunities-service/action';
import {
	selectorGetManualOpportunitiesCount,
	selectorGetPastOpportunitiesCount,
	selectorGetTotalOpportunitiesCount,
	selectorGetUpcomingOpportunitiesCount,
} from 'redux/opportunities-service/selector';
// type
import { AllOpposQuery, PastOpposQuery, UpcomingOpposQuery, ManualOpposQuery } from 'core/types/OPPORTUNITIES_QUERIES';
// components
import OpportunitiesTable, { OpportunitiesRequestBody } from 'components/Organization/Opportunities/Table';
import TabNavItem from '../TabNav/TabNavItem';
import TabNav from '../TabNav';
// style
import styles from './OppListContent.module.scss';
import { Placeholders } from 'components/Organization/Placeholders';
import { Store } from '../../../redux/root';

export enum OPPORTUNITIES_TABS {
	ALL = 'ALL',
	UPCOMING = 'UPCOMING',
	MANUAL = 'MANUAL',
	PAST = 'PAST',
	PLACEHOLDER = 'PLACEHOLDER',
}

const AllTabQueryPreset = {
	...AllOpposQuery,
	oppoFilterType: OPPORTUNITIES_TABS.ALL,
};
const PastTabQueryPreset = {
	...PastOpposQuery,
	oppoFilterType: OPPORTUNITIES_TABS.PAST,
};
const UpcomingTabQueryPreset = {
	...UpcomingOpposQuery,
	oppoFilterType: OPPORTUNITIES_TABS.UPCOMING,
};
const ManualTabQueryPreset = {
	...ManualOpposQuery,
	oppoFilterType: OPPORTUNITIES_TABS.MANUAL,
};
const PlaceholderTabQueryPreset = {
	...ManualOpposQuery,
	oppoFilterType: OPPORTUNITIES_TABS.PLACEHOLDER,
};

const QueryPreset = new Map();
QueryPreset.set(OPPORTUNITIES_TABS.ALL, AllTabQueryPreset);
QueryPreset.set(OPPORTUNITIES_TABS.PAST, PastTabQueryPreset);
QueryPreset.set(OPPORTUNITIES_TABS.UPCOMING, UpcomingTabQueryPreset);
QueryPreset.set(OPPORTUNITIES_TABS.MANUAL, ManualTabQueryPreset);
QueryPreset.set(OPPORTUNITIES_TABS.PLACEHOLDER, PlaceholderTabQueryPreset);

export const OppoContext = createContext({});

const parseQueryString = (search: string): OpportunitiesRequestBody => {
	const searchParams: any = qs.parse(search, { ignoreQueryPrefix: true });
	// if (searchParams.showVacancies) searchParams.showVacancies = !!searchParams.showVacancies;
	return searchParams;
};

const OppListContent: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [activeTab, setActiveTab] = useState<OPPORTUNITIES_TABS>(OPPORTUNITIES_TABS.ALL);
	const [requestBody, setRequestBody] = useState<OpportunitiesRequestBody | undefined>(QueryPreset.get(activeTab));

	const opportunitiesManualCount = useSelector(selectorGetManualOpportunitiesCount);
	const opportunitiesPastCount = useSelector(selectorGetPastOpportunitiesCount);
	const opportunitiesTotalCount = useSelector(selectorGetTotalOpportunitiesCount);
	const opportunitiesUpcomingCount = useSelector(selectorGetUpcomingOpportunitiesCount);
	const placeholdersCount = useSelector((store: Store) => store.placeholdersRedux.total);

	const [isClearSearch, setIsClearSearch] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const allTabClickHandler = () => {
		setActiveTab(OPPORTUNITIES_TABS.ALL);
	};
	const pastTabClickHandler = () => {
		setActiveTab(OPPORTUNITIES_TABS.PAST);
	};
	const upcomingTabClickHandler = () => {
		setActiveTab(OPPORTUNITIES_TABS.UPCOMING);
	};
	const manualTabClickHandler = () => {
		setActiveTab(OPPORTUNITIES_TABS.MANUAL);
	};
	const placeholderTabClickHandler = () => {
		setActiveTab(OPPORTUNITIES_TABS.PLACEHOLDER);
	};

	const generalTabClickHandler = async () => {
		setIsClearSearch(true);
		setPagination(INITIAL_PAGINATION);
		dispatch(resetError());
		dispatch(resetOpportunities());
		!isFirstLoad && setRequestBody(QueryPreset.get(activeTab));
		isFirstLoad && setIsFirstLoad(false);
	};
	useEffect(() => {
		generalTabClickHandler();
	}, [activeTab]);

	useEffect(() => {
		return () => {
			dispatch(resetOpportunities());
			dispatch(resetError());
			generalTabClickHandler();
		};
	}, []);

	useEffect(() => {
		dispatch(resetOpportunities());
		setPagination(INITIAL_PAGINATION);
		setRequestBody({ ...QueryPreset.get(activeTab), ...parseQueryString(location.search) });
	}, [location.search]);

	return (
		<div className={styles.container}>
			<TabNav>
				<TabNavItem
					counter={opportunitiesTotalCount || 0}
					title="All"
					activeTab={activeTab === OPPORTUNITIES_TABS.ALL}
					clickHandler={allTabClickHandler}
				/>
				<TabNavItem
					counter={opportunitiesPastCount || 0}
					activeTab={activeTab === OPPORTUNITIES_TABS.PAST}
					title="Past"
					clickHandler={pastTabClickHandler}
				/>
				<TabNavItem
					counter={opportunitiesUpcomingCount || 0}
					activeTab={activeTab === OPPORTUNITIES_TABS.UPCOMING}
					title="Upcoming"
					clickHandler={upcomingTabClickHandler}
				/>
				<TabNavItem
					counter={opportunitiesManualCount || 0}
					activeTab={activeTab === OPPORTUNITIES_TABS.MANUAL}
					title="Manual"
					clickHandler={manualTabClickHandler}
				/>
				<TabNavItem
					counter={placeholdersCount || 0}
					activeTab={activeTab === OPPORTUNITIES_TABS.PLACEHOLDER}
					title="Placeholders"
					clickHandler={placeholderTabClickHandler}
				/>
			</TabNav>
			<OppoContext.Provider value={{ isClearSearch, setIsClearSearch }}>
				{activeTab === OPPORTUNITIES_TABS.PLACEHOLDER ? (
					<Placeholders />
				) : (
					<OpportunitiesTable
						requestBody={requestBody}
						pagination={pagination}
						setPagination={setPagination}
						sort={requestBody?.sort}
						setRequestBody={setRequestBody}
						// changeVisibilityFilters={() => console.log('oppoList')}
					/>
				)}
			</OppoContext.Provider>
		</div>
	);
};

export default OppListContent;
