import React, { FC, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { getUserPhotoSrc } from 'core/functions';
import styles from './AvatarCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';
import { IMAGE_WITH_POPUP_TYPE } from 'core/types';
import { IOpportunityResponse, OPPORTUNITY_TYPES } from '@joc/api-gateway';

type AvatarCellProps = {
	imagePath: string | undefined;
	opportunityType?: IMAGE_WITH_POPUP_TYPE;
	opportunity?: IOpportunityResponse;
	parentClassName?: string;
};

const AvatarCell: FC<AvatarCellProps> = ({
	imagePath,
	opportunityType,
	opportunity,
	parentClassName,
}: AvatarCellProps) => {
	const [isImageLoadError, setIsImageLoadError] = useState(false);
	const [correctImagePath, setImagePath] = useState<string>();

	const onError = () => setIsImageLoadError(true);

	useEffect(() => {
		if (
			opportunity?.opportunityType !== OPPORTUNITY_TYPES.MANUAL &&
			!opportunity?.filePathes?.length &&
			!opportunity?.imagePath
		) {
			const logoImagePath = opportunity?.organisation?.organizationLogoPath;
			setImagePath(
				logoImagePath
					? `${process.env.REACT_APP_S3_API}/${logoImagePath}`
					: getUserPhotoSrc(isImageLoadError ? null : imagePath, opportunityType)
			);
		} else {
			setImagePath(getUserPhotoSrc(isImageLoadError ? null : imagePath, opportunityType));
		}
	}, [imagePath, opportunityType, isImageLoadError]);

	return (
		<div className={cx(styles.avatar, defaultStyles.main, parentClassName)}>
			<img src={correctImagePath} alt="avatar" onError={onError} />
		</div>
	);
};

export default AvatarCell;
