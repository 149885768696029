import React, { BaseSyntheticEvent, FC, ReactElement } from 'react';
import { ButtonBase } from '@material-ui/core';
import cx from 'classnames';
import styles from './index.module.scss';

interface ButtonDefaultProps {
	title: string;
	classList: string | Array<string>;
	parentClassName?: string;
	disabled?: boolean;
	submitType?: boolean;
	clickHandler?: (() => void) | ((e: BaseSyntheticEvent) => void);
	icon?: ReactElement;
	fontPlusJakartaSans?: boolean;
}
const ButtonDefault: FC<ButtonDefaultProps> = ({
	title,
	classList,
	disabled,
	submitType,
	clickHandler,
	parentClassName,
	fontPlusJakartaSans,
	icon,
}: ButtonDefaultProps) => {
	return (
		<ButtonBase
			type={submitType ? 'submit' : 'button'}
			className={cx(
				styles.btn_default,
				{
					[styles.primary]: classList.includes('primary'),
					[styles.submit]: classList.includes('submit'),
					[styles.secondary]: classList.includes('secondary'),
					[styles.secondary_active]: classList.includes('secondary_active'),
					[styles.lg]: classList.includes('lg'),
					[styles.extra_lg]: classList.includes('extra_lg'),
					[styles.md]: classList.includes('md'),
					[styles.sm]: classList.includes('sm'),
					[styles.nano]: classList.includes('nano'),
					[styles.extra_md]: classList.includes('extra_md'),
					[styles.center]: classList.includes('center'),
					[styles.disabled]: disabled,
					[styles.fontPlusJakartaSans]: fontPlusJakartaSans,
				},
				parentClassName && parentClassName
			)}
			disabled={disabled}
			// eslint-disable-next-line no-return-await
			onClick={clickHandler}
		>
			{icon || null}
			{title}
		</ButtonBase>
	);
};
export default ButtonDefault;
